/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

$(".hamburger").click(function(){
  $(this).toggleClass("is-active");
  $(".nav-primary").animate({
      //width: "toggle",
      opacity: "toggle"
  }, 300);
});

$('.slider-default').slick({
  nextArrow: '<span class="btn btn-square slick-arrow arrow-right"><i class="fas fa-angle-right"></i></span>',
  prevArrow: '<span class="btn btn-square slick-arrow arrow-left"><i class="fas fa-angle-left"></i></span>',
  dots: true,
});

$('.slider-default-auto').slick({
  nextArrow: '<span class="btn btn-square slick-arrow arrow-right"><i class="fas fa-angle-right"></i></span>',
  prevArrow: '<span class="btn btn-square slick-arrow arrow-left"><i class="fas fa-angle-left"></i></span>',
  autoplay: true,
  autoplaySpeed: 7000,
  dots: true,
});

$('.slider-product').slick({
  nextArrow: '<span class="btn btn-square slick-arrow arrow-right"><i class="fas fa-angle-right"></i></span>',
  prevArrow: '<span class="btn btn-square slick-arrow arrow-left"><i class="fas fa-angle-left"></i></span>',
  autoplay: false,
  speed: 300,
  dots: false,
  arrows: false,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  asNavFor: '.slider-product-thumb',
  waitForAnimate: false,
  adaptiveHeight: true,
});

$('.slider-product-thumb').slick({
  nextArrow: '<span class="btn btn-square slick-arrow arrow-right"><i class="fas fa-angle-right"></i></span>',
  prevArrow: '<span class="btn btn-square slick-arrow arrow-left"><i class="fas fa-angle-left"></i></span>',
  autoplay: false,
  dots: false,
  arrows: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  asNavFor: '.slider-product',
  centerMode: false,
  focusOnSelect: true,
  infinite: true,
});

$('.slider-referenties').slick({
  nextArrow: '<span class="btn btn-square slick-arrow arrow-right"><i class="fas fa-angle-right"></i></span>',
  prevArrow: '<span class="btn btn-square slick-arrow arrow-left"><i class="fas fa-angle-left"></i></span>',
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 7000,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

/*
$('.scroll-to').on('click',function(e) {
  e.preventDefault();



  var offset = ($('.banner').height() + $('#wpadminbar').height());
  var target = this.hash;
  if ($(this).data('offset') !== undefined) { offset = $(this).data('offset'); }
  $('html, body').stop().animate({
    'scrollTop': $(target).offset().top - offset
  }, 500, 'swing', function() {
    // window.location.hash = target;
  });
});
*/

  $("a").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();


      // Store hash
      var hash = this.hash;
      var offset = ($('.banner').height() + $('#wpadminbar').height() + 40);

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top - offset
      }, 800, function(){
   
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });



function blokkenheight() {
   
  $('.products').each(function(){
  
    $('.shoploop-product-info',this).height('auto');

    //if ($(window).width() > 767) {
      var highestBox = 0;
      $('.shoploop-product-info', this).each(function(){

          if($(this).height() > highestBox) {
             highestBox = $(this).height(); 
           }
      });  

      $('.shoploop-product-info',this).height(highestBox);
    //}

  });

}

blokkenheight();

$(window).resize(function() {
  blokkenheight();
});


$('.grid').imagesLoaded( function() {
  $('.grid').masonry({
    // options
    itemSelector: '.grid-item',
  });
});

$('.popup-lookbook').magnificPopup({
  type: 'image'
  // other options
});



$('a[href="#brochure-form"]').magnificPopup({
  type:'inline',
  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

    var feed = new Instafeed({
        get: 'user',
        accessToken: InstagramToken,
        limit: 12,
        template: '<div class="instafeed-item"><div class="instafeed-item-image" style="background-image:url({{image}});"><a href="{{link}}" target="_blank"><i class="fab fa-instagram"></i></a></div></div>',
        //template: '<div class="instafeed-item"><div style="display:block; width: 100%;padding-top:100%;background-image:url({{image}});background-size:cover;"></div></div>',
        after: function(image) {
            
$('.multiple-items').slick({
  infinite: true,
  slidesToShow: 8,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 8000,
  speed: 400,
  //touchThreshold: 100,
  //edgeFriction: 0,
  swipeToSlide: true,
  cssEase: 'ease-in-out',
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 7,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 6,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 5,
      }
    },
    {
      breakpoint: 479,
      settings: {
        slidesToShow: 4,
      }
    }
  ]
});

        }
    });
    feed.run();



      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
